import garantiasAdminApi from "../garantias_admin.api";
import utils from "@/helpers/utilidades";


export default {

  get_origen(identificador_origen){
    const options = {
      relations: ['campos'],
      limit: 1,
      filters: [{
        relacion: "and",
        columna: "identificador",
        operador: "equal_to",
        valor1: identificador_origen,
        valor2: null
      }]
    }

    return garantiasAdminApi.api.get(utils.url_options(`${garantiasAdminApi.baseURL}/origenes_datos`,options, false));
  }

  ,consultar_origen(identificador_origen, payload){
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/origenes_datos/consultar/${identificador_origen}`,payload);
  }
}