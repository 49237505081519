<template>
  <div>
    <label :for="clave" class="form-label label-campo" :title="label">
      <i v-if="is_required" class="fa fa-check-circle text-primary mr-sm-1"></i>
      <i class="fa fa-refresh text-info"></i>
      <slot name="label"></slot>
      {{label}}
      <slot name="label-right"></slot>
    </label>

    <template  v-if="tipo === 'array'">
      <select v-if="!select_clave_valor" class="form-control" :name="clave" :id="clave"  v-model="model"  :disabled="disabled">
        <option  v-for="opcion in opciones_select" :value="opcion">{{opcion}}</option>
      </select>

      <select v-else class="form-control" :name="clave" :id="clave"  v-model="model"  :disabled="disabled">
        <option  v-for="opcion in opciones_select" :value="opcion[select_opciones_valor]">{{opcion[select_opciones_label]}}</option>
      </select>
    </template>

    <IC v-if="tipo === 'money'" v-model="model" label="$" :id="clave" :name="clave" :formats="['moneyFormat']" :disabled="disabled"/>

    <IC v-if="tipo === 'percentage'" v-model="model" label="%" :id="clave" :name="clave" :custome="percentageFormat" :formats="['custome']" :disabled="disabled"/>

    <input v-if="tipo === 'string' || tipo === 'integer'" :type="tipos_campos[tipo]" :id="clave" :name="clave" v-model="model" class="form-control" :disabled="disabled"/>

    <input v-if="tipo === 'date'" :type="tipos_campos[tipo]" :id="clave" :name="clave" v-model="model" class="form-control" :disabled="disabled"/>

  </div>
</template>

<script>
import IC from "@/components/Form/InputCompuesto.vue";

export default {
  name: 'CampoDinamico',
  components: {IC},
  props: {
    clave: {
      type: String,
      required: true,
    },
    tipo: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    valor: {
      required: true,
    },
    select_clave_valor: {
      type: Boolean,
      default(){
        return false
      }
    }, //key del valor que tendrá el
    select_opciones_label:'', //key del valor que tendrá el
    select_opciones_valor:'', //key del valor que tendrá el
    opciones_select: {
      type: Array,
      required: true,
      default(){
        return [];
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
    ,required:{
      type: Boolean|Number,
      required: false,
      default(){
        return false
      }
    }
  },
  data(){
    return {
      tipos_campos: {string: 'text', integer: 'number', percentage: 'number', date:'date'}
    }
  },
  computed: {
    model: {
      get() {
        return this.valor;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    type(){
      return this.tipos_campos[this.tipo];
    }
    ,is_required(){
      if (this.required === Number)
        return this.required === 1;
      return  this.required;
    }
  },

  methods:{
    emit_cambio_valor($event){
      this.$emit('update:modelValue', $event.target.value)
    }
    ,percentageFormat (value) {
      return !value ? '0' :  parseFloat(value).toFixed(2);
    }
  }
}
</script>

<style scoped>
.label-campo{
  white-space: nowrap;
  text-overflow:ellipsis;
  overflow: hidden;
  display: block;
}
</style>
