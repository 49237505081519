<template>


    <Modal :options="{width: '70vw'}">
      <div class="title h2 text-white">Cargar origen</div>

      <div class="text-center my-4">
        <h5>Ingresa los datos para poder cargar el origen.</h5>
      </div>

      <div class="body">

        <div class="alert alert-primary mb-5" role="alert">
          <i class="fa fa-info-circle mr-sm-1"></i> <b>Importante:</b>
          La información que se pide a continuación es requerida por el origen para poder consultar sus datos. <br>
          La consulta se realiza directamente al origen por lo que el tiempo de respuesta puede depender de cuanto
          tarde el servicio de origen externo en responder.
        </div>

        <div class="row justify-content-start">
          <div class="col-sm-3 form-group" v-for="param_request in origen.parametros_request">
            <label for="calle" class="form-label" >{{ param_request.clave_campo }}</label>
            <input type="text" :id="param_request.clave_campo" :name="param_request.clave_campo" v-model="formulario[param_request.clave_campo]" class="form-control"/>
          </div>
        </div>
      </div>

      <div class="footer mb-4">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-danger" @click="cancelar"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
          <div class="col-auto"><button type="button" class="btn btn-success" @click="enviar" ><i class='fa fa-save mr-sm-1'></i>Enviar</button></div>
        </div>
      </div>

    </Modal>





</template>

<script>

import Modal from "@/components/Modal.vue";


export default {
  name: 'FormularioParamsOrigen',
  components: {Modal},
  props: {
    origen:{
      type: Object,
      default() {
        return {
          id: null,
          identificador: null,
          origen_asincrono: false,
          parametros_request:[]
        };
      }
    }
  },
  data(){
    return {
      formulario: {},
      formulario_params_origen:false
    }
  },
  computed: {

  }
  ,mounted() {
    for (const parametro of this.origen.parametros_request) {
      this.formulario[parametro.clave] = null;
    }

  }

  ,methods:{
    cancelar(){
      this.$emit('cancelar')
    },
    enviar(){
      this.$emit('enviar', this.formulario)
    }
  }
}
</script>

<style scoped>

</style>
