
<template>

  <div>
    <Modal :options="{width: '75vw'}" @close="cancelar">
      <div class="title h2 text-white">{{ !garantia.id ? 'Agregar' : 'Editar' }} garantia</div>

      <div class="text-center my-4">
        <h5>Ingresa los datos de la garantia.</h5>
      </div>

      <div class="body">

        <div class="alert alert-primary mb-5" role="alert" v-if="!solo_lectura">
          <i class="fa fa-info-circle mr-sm-1"></i> <b>Importante:</b> Los campos que contengan los iconos:
          <ul class="list-group border-0 mt-2">
            <li class="list-group-item border-0 py-0 bg-transparent"><i class="fa fa-external-link mx-sm-1"></i> indica los campos que pueden tener valores que se van a cargar o ya fueron cargados desde un origen externo, si edita los valores obtenidos ya no podrán recuperarse.</li>
            <li class="list-group-item border-0 py-0 bg-transparent"><i class="fa fa-refresh mx-sm-1"></i> hacen referencia a campos dinámicos configurados por el administrador y pueden cambiar en cualquier momento.</li>
            <li class="list-group-item border-0 py-0 bg-transparent"><i class="fa fa-check-circle mx-sm-1"></i> indica que el campo es obligatorio.</li>
          </ul>
        </div>

        <div class="text-right mb-5" v-if="ver_boton_cargar_origen" >
          <button class="btn btn-sm btn-primary" @click="pre_cargar_origen()"> <i class='fa fa-external-link mr-sm-1'></i>Cargar desde origen externo</button>
        </div>

        <div class="row justify-content-start">

          <div class="col-sm-3 form-group">
            <label for="identificador"  class="form-label text-truncate d-block" title="Identficador externo/folio">
              Identificador externo/folio
            </label>
            <div class="text-bold" v-if="solo_lectura"><b>{{garantia.identificador}}</b></div>
            <button  v-if="campo_origen_externo('external_id')" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El valor del campo se cargó desde un origen externo"><i class="fa fa-external-link"></i></button>
            <input v-if="!solo_lectura" v-model="garantia.identificador" type="text" name="identificador" id="identificador" class="form-control">
          </div>

          <div class="col-sm-3 form-group">
            <label for="estatus"  class="form-label text-truncate d-block" title="Estatus">
              <i class="fa fa-check-circle text-primary"></i>
              <button  v-if="campo_origen_externo('estatus')" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El valor del campo se cargó desde un origen externo"><i class="fa fa-external-link"></i></button>
              Estatus
            </label>
            <div class="text-bold" v-if="solo_lectura"><b>{{garantia.tipo_inmueble}}</b></div>
            <select v-else class="form-control" name="estatus" id="estatus"  v-model="garantia.estatus" >
              <option value="activo">Activo</option>
              <option value="inactivo">Inactivo</option>
            </select>
          </div>

          <div class="col-sm-3 form-group">
            <label for="tipo_inmueble"  class="form-label text-truncate d-block" title="Tipo de inmueble">
              <i class="fa fa-check-circle text-primary"></i>
              <button  v-if="campo_origen_externo('tipo_inmueble')" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El valor del campo se cargó desde un origen externo"><i class="fa fa-external-link"></i></button>
              Tipo de inmueble
            </label>
            <div class="text-bold" v-if="solo_lectura"><b>{{garantia.tipo_inmueble}}</b></div>
            <select v-else class="form-control" name="tipo_inmueble" id="tipo_inmueble"  v-model="garantia.tipo_inmueble" >
              <option :value="tipo_inuemble_item.valor" v-for="tipo_inuemble_item in tipos_inmuebles">{{tipo_inuemble_item.nombre}}</option>
            </select>
          </div>
          <div class="col-sm-3 form-group">
            <label for="estado"  class="form-label text-truncate d-block" title="Estado">
              <i class="fa fa-check-circle text-primary"></i>
              <button  v-if="campo_origen_externo('estado')" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El valor del campo se cargó desde un origen externo"><i class="fa fa-external-link"></i></button>
              Estado
            </label>
            <div class="text-bold" v-if="solo_lectura"><b>{{garantia.estado}}</b></div>
            <select v-else class="form-control" name="estado" id="estado"  v-model="garantia.estado">
              <option :value="estado.estado" v-for="estado in estados">{{estado.estado}}</option>
            </select>
          </div>

          <div class="col-sm-3 form-group">
            <label for="municipio"  class="form-label text-truncate d-block" title="Alcaldía/Municipio">
              <i class="fa fa-check-circle text-primary"></i>
              <button  v-if="campo_origen_externo('municipio')" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El valor del campo se cargó desde un origen externo"><i class="fa fa-external-link"></i></button>
              Alcaldía/Municipio
            </label>
            <div class="text-bold" v-if="solo_lectura"><b>{{garantia.municipio}}</b></div>
            <select v-else class="form-control" name="municipio" id="municipio"  v-model="garantia.municipio" >
              <option :value="municipio.municipio" v-for="municipio in municipios">{{municipio.municipio}}</option>
            </select>
          </div>

          <div class="col-sm-3 form-group">
            <label for="cp"  class="form-label text-truncate d-block" title="C.P.">
              <i class="fa fa-check-circle text-primary"></i>
              <button  v-if="campo_origen_externo('cp')" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El valor del campo se cargó desde un origen externo"><i class="fa fa-external-link"></i></button>
              C.P.
            </label>
            <div class="text-bold" v-if="solo_lectura"><b>{{garantia.cp}}</b></div>
            <input v-else v-model="garantia.cp" type="number" name="cp" id="cp" class="form-control" max="5">
          </div>

          <div class="col-sm-3 form-group">
            <label for="calle"  class="form-label text-truncate d-block" title="Calle">
              <i class="fa fa-check-circle text-primary"></i>
              <button  v-if="campo_origen_externo('calle')" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El valor del campo se cargó desde un origen externo"><i class="fa fa-external-link"></i></button>
              Calle
            </label>
            <div class="text-bold" v-if="solo_lectura"><b>{{garantia.calle}}</b></div>
            <input v-else v-model="garantia.calle" type="text" name="calle" id="calle" class="form-control">
          </div>

          <div class="col-sm-3 form-group">
            <label for="numero_exterior"  class="form-label text-truncate d-block" title="Número exterior">
              <i class="fa fa-check-circle text-primary"></i>
              <button  v-if="campo_origen_externo('numero_exterior')" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El valor del campo se cargó desde un origen externo"><i class="fa fa-external-link"></i></button>
              Número exterior
            </label>
            <div class="text-bold" v-if="solo_lectura"><b>{{garantia.numero_exterior}}</b></div>
            <input v-else v-model="garantia.numero_exterior" type="number" name="numero_exterior" id="numero_exterior" class="form-control" max="5">
          </div>

          <div class="col-sm-3 form-group">
            <label for="numero_interior"  class="form-label text-truncate d-block" title="Número interior">
              <button  v-if="campo_origen_externo('numero_interior')" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El valor del campo se cargó desde un origen externo"><i class="fa fa-external-link"></i></button>
              Número interior
            </label>
            <div class="text-bold" v-if="solo_lectura"><b>{{garantia.numero_interior}}</b></div>
            <input v-else v-model="garantia.numero_interior" type="text" name="numero_interior" id="numero_interior" class="form-control" max="5">
          </div>

          <div class="col-sm-3 form-group">
            <label for="colonia"  class="form-label text-truncate d-block" title="Colonia">
              <i class="fa fa-check-circle text-primary"></i>
              <button  v-if="campo_origen_externo('colonia')" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El valor del campo se cargó desde un origen externo"><i class="fa fa-external-link"></i></button>
              Colonia
            </label>
            <div class="text-bold" v-if="solo_lectura"><b>{{garantia.colonia}}</b></div>
            <input v-else v-model="garantia.colonia" type="text" name="colonia" id="colonia" class="form-control">
          </div>

          <div class="col-sm-3 form-group" v-for="campo in campos_dinamicos ">
            <label v-if="solo_lectura" class="form-label text-truncate d-block" :title="campo.nombre">
              {{ campo.nombre }}
            </label>
            <div class="text-bold" v-if="solo_lectura"><b>{{garantia[campo.clave]}}</b></div>
            <campo-dinamico v-else
                            :label="campo.nombre"
                            :clave="campo.clave"
                            :tipo="campo.tipo_dato"
                            :valor="garantia[campo.clave]"
                            :opciones_select="campo.tipo_dato === 'array' ? campo.valores : []"
                            v-model="garantia[campo.clave]"
            >
              <template v-slot:label>
                <button  v-if="campo.es_de_origen_externo" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El valor del campo se cargó desde un origen externo"><i class="fa fa-external-link"></i></button>
              </template>
            </campo-dinamico>


          </div>



          <div class="col-sm-12 form-group">
            <label for="ubicacion"  class="form-label text-truncate d-block" title="Ubicación del inmueble (link de ubicación google maps)">
              <i class="fa fa-check-circle text-primary"></i>
              Ubicación del inmueble <small class="text-muted">(link de ubicación google maps)</small>
            </label>
            <div class="text-bold" v-if="solo_lectura"><b>{{garantia.ubicacion}}</b></div>
            <input v-else v-model="garantia.ubicacion" type="text" name="ubicacion" id="ubicacion" class="form-control" placeholder="https://maps.app.goo.gl/3u2HVvEMETQWxeGE6">
          </div>

        </div>


        <div class="footer mb-4">
          <div class="row justify-content-end">
            <div class="col-auto"><button class="btn btn-danger" @click="cancelar"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
            <div class="col-auto" v-if="!solo_lectura"><button type="button" class="btn btn-success" @click="guardar" id="btn-crear" name="btn-crear"><i class='fa fa-save mr-sm-1'></i>Guardar
              {{ !garantia.id ? '' : 'cambios' }}</button></div>
          </div>
        </div>

      </div>


    </Modal>

    <FormularioParamsOrigen v-if="form_parametros_cargar_origen_datos" :origen="configuracion_origen_datos" @enviar="form_origen_enviar($event)" @cancelar="cancelar_form_params_origen()"></FormularioParamsOrigen>
    <FormulatioSeleccionarOrigen v-if="form_seleccion_origen_de_datos" :response_origen="origen_datos_response" @cancelar="cancelar_form_seleccion_origen()" @seleccion="asignar_valores_origen($event)"></FormulatioSeleccionarOrigen>

  </div>


</template>

<script>


import Modal from "@/components/Modal.vue";
import CampoDinamico from "@/apps/garantias_app/components/CampoDinamico.vue";
import garantiasApi from "@/apps/garantias_app/api/personas/creditos/garantias.api";
import catalogosApi from "@/apps/garantias_app/api/catalogos/catalogos.api";
import origenDatosApi from "../../../../api/personas/origen_datos.api";
import FormularioParamsOrigen from "../../../../components/FormularioParamsOrigen.vue";
import FormulatioSeleccionarOrigen from "../../../../components/FormularioSeleccionOrigen.vue";

export default {
  name: "FormularioGarantia"
  ,components: {FormulatioSeleccionarOrigen, FormularioParamsOrigen, CampoDinamico, Modal}
  ,props: {
    persona_id: null,
    credito_id: null,
    garantia_id: {
      type: String | null,
      required: false,
      default() {
        return null
      },
    },
    solo_lectura: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
  }
  ,data() {
    return {
      paises: ['México']
      ,tipos_inmuebles: []
      ,campos_dinamicos: []
      ,garantia:{
        id: null
        ,identificador: null
        ,estatus: null
        ,tipo_inmueble: null
        ,pais: 'México'
        ,estado: null
        ,municipio: null
        ,cp: null
        ,calle: null
        ,numero_interior: null
        ,numero_exterior: null
        ,colonia: null
        ,ubicacion: null
        , propiedades: []
        , historico: []
      }
      ,estados: [],
      municipios:[]
      ,origen_datos_response:[]
      ,form_seleccion_origen_de_datos: false
      ,configuracion_origen_datos: {
        id: null,
        identificador: null,
        origen_asincrono: false,
        parametros_request:[],
        campos:[] //Las propiedades que estan configuradas para ser cargadas desde el origen
      },
      form_parametros_cargar_origen_datos: false,
      municipio_response_origen_datos: null //tendrá el municipio cargado del origen externo en caso de que la data se haya cargado de un origen externo si no el valor siempre será nulo
    }
  }
  ,mounted() {
   this.cargar_data()
  }
  ,computed:{
    editar_garantia(){
      return (typeof this.garantia_id == "string");
    }
    ,ver_boton_cargar_origen(){
      return !this.editar_garantia && this.configuracion_origen_datos.origen_asincrono
    }
  }
  ,methods: {

    campo_origen_externo(clave_campo){
      return this.configuracion_origen_datos.campos &&
          this.configuracion_origen_datos.campos.find(campo => !campo.campo_id && campo.nombre_campo_local === clave_campo);
    },
    async cargar_data() {

      if (!this.editar_garantia){
        await this.validar_origen()
      }

      if (!this.solo_lectura) {
        await this.cargar_estados()
        await this.cargar_tipos_inmuebles()
      }
      await this.cargar_garantia()
    },


    async cargar_estados() {
      const request = await catalogosApi.estados();
      this.estados = request.data
    },

    async cargar_municipios(){
      let estado = this.estados.find(item  => item.estado === this.garantia.estado);
      const request =  await catalogosApi.munincipios(estado.id);
      this.municipios = request.data
    },

    async cargar_tipos_inmuebles() {
      const request = await catalogosApi.buscar('tipo_inmueble');
      this.tipos_inmuebles = request.data.opciones
    },


    async cargar_garantia(){
      if (this.editar_garantia) {
        const dataSource = await garantiasApi.obtener(this.persona_id, this.credito_id, this.garantia_id)
        this.garantia = {identificador: dataSource.data.external_id, ...dataSource.data}
      }

      await this.cargarCampos()
    },
    cancelar(){
      this.$emit('cancelar')
    }
    ,async guardar(){
      let isValid = await this.$validator({
        tipo_inmueble: !this.garantia.tipo_inmueble ? 'required' : 'max:45',
        calle: !this.garantia.calle ? 'required' : 'max:255',
        numero_exterior: !this.garantia.numero_exterior ? 'required' : 'max:5',
        numero_interior: !this.garantia.numero_interior ? '' : 'max:5',
        colonia: !this.garantia.colonia ? 'required' : 'max:255',
        cp: !this.garantia.cp ? 'required' : 'max:5',
        pais: !this.garantia.pais ? 'required' : 'max:255',
        estado: !this.garantia.estado ? 'required' : '',
        municipio: !this.garantia.municipio ? 'required' : 'max:255',
        ubicacion: !this.garantia.ubicacion ? 'required' : 'max:255',
        estatus: !this.garantia.estatus ? 'required' : '',


      })

      if (isValid) {

        try {
          if (this.editar_garantia) {
            await garantiasApi.editar(this.persona_id, this.credito_id, this.garantia_id, this.garantia)
            this.$helper.showMessage('Editar garantia', 'Información de garantia guardada exitosamente.', 'success', 'alert')

          } else {
            let request = await garantiasApi.crear(this.persona_id, this.credito_id, this.garantia)
            this.garantia = {identificador: request.data.external_id, ...request.data}

            this.$helper.showMessage('Crear garantia', 'Información del garantia guardada exitosamente.', 'success', 'alert')
            await this.cargarCampos()
          }

          this.$emit('actualizar', this.garantia)

        } catch (e) {
          this.$log.error('Error', e);
          if (e.response && e.response.status !== 400)
            this.$helper.showMessage((!this.garantia_id ? 'Guardar' : 'Editar') + ' garantia', 'Ocurrio un error al guardar datos del garantia, favor de reportarlo al administador.', 'error', 'alert')
        }


      }
    }

    , async cargarCampos() {

      let dataSource = await garantiasApi.campos_dinamicos(this.persona_id)
      this.campos_dinamicos = dataSource.data

      for (const campoKey in this.campos_dinamicos) {

        const campo = this.campos_dinamicos[campoKey]

        /**
         * Si no esta editando se descartan los campos dinámicos que no esten activos para
         * el nuevo registro
         */
        if (!this.editar_garantia){
          if (!campo.activo) {
            this.campos_dinamicos.splice(campoKey,1)
          }else
            this.garantia[campo.clave] = null

          continue
        }

        let propiedad = {}

        /**
         * Validar si se esta editando un registro y buscar en sus valores de campos de historico o propiedades los valores de los campos dinamicos
         * las propiedades se obtienen al consultar el registro en el api y se asigna sus valores
         * */
        if(this.garantia.propiedades || this.garantia.historico){

          propiedad = this.garantia.historico.find(campo_historico => campo_historico.campo_id === campo.id)

          if (!propiedad)
            propiedad = this.garantia.propiedades.find(propiedad => propiedad.campo_id === campo.id)



          /**
           * Se omiten los campos dinámicos que esten inactivos si la propiedad para el mismo no existe en
           * las propiedades del registro en edición
           */
          if (!campo.activo && !propiedad) {
            this.campos_dinamicos.splice(campoKey,1)
            continue
          }
        }

        this.garantia[campo.clave] = propiedad && propiedad.hasOwnProperty('valor')  ? propiedad.valor : null
      }
    }

    /**
     * Valida si hay un origen de datos para el formulario y habilitar botón para cargar origen de datos
     * Para que la asignación de la propiedad es_de_origen_externo sea correcta para los campos dinámicos
     * primero se debieron haber cargado los campos dinámicos del formulario.
     * @returns {Promise<void>}
     */
    , async validar_origen() {

      const request = await origenDatosApi.get_origen('formulario_garantia')
      if (request.data[0] && request.data[0].origen_asincrono) {
        // Hablilita botón para ingresar datos necesarios para cargar origen de datos
        this.configuracion_origen_datos =  request.data[0]
      }

      //Validar que campos van a tener el ícono de origen externo en el formulario tanto para los campos dinámicos
      if (this.configuracion_origen_datos.campos){
        for (const campo_origen of this.configuracion_origen_datos.campos) {
            //Se asigna la propiedad es_de_origen_externo a los campos dinámicos que esten en la configuración de campos de origen externo
            const index_campo_dinamico = this.campos_dinamicos.findIndex( campo_dinamico => campo_dinamico.id = campo_origen.campo_id)
            if (index_campo_dinamico >= 0){
              this.campos_dinamicos[index_campo_dinamico].es_de_origen_externo = true
            }
        }
      }

    }
    ,cancelar_form_params_origen(){
      this.form_parametros_cargar_origen_datos = false
    }
    ,cancelar_form_seleccion_origen(){
      this.form_seleccion_origen_de_datos= false
    }
    , pre_cargar_origen() {
      if (this.configuracion_origen_datos.parametros_request.length > 0)
        this.form_parametros_cargar_origen_datos = true
      else
        this.cargar_origen()

    }

    ,form_origen_enviar(event){
      this.form_parametros_cargar_origen_datos = false
      this.cargar_origen(event)
    }

    , async cargar_origen(request_params = {}) {

      const payload = {...this.garantia, ...request_params}
      const dataSource = await garantiasApi.formulario(payload)
      this.origen_datos_response = dataSource.data

      if (this.origen_datos_response.length && this.origen_datos_response.length > 0)
        this.form_seleccion_origen_de_datos = true
      else
        //Asignar los valores del unico resultado obtenido
        this.asignar_valores_origen(this.origen_datos_response[0]);

    },

    asignar_valores_origen(data){
      this.municipio_response_origen_datos = null
      this.form_seleccion_origen_de_datos = false
      const campos_dinamicos_valores = !data.campos_dinamicos ? [] : data.campos_dinamicos

      //Asignar valores a propiedades de garantía
      for (const [key, value] of Object.entries(data.valores_origen)) {
        if (this.garantia.hasOwnProperty(key)) {
            this.garantia[key] = value
        }
      }
      //Asignar valores de campos dinámicos
      for (const campo of campos_dinamicos_valores) {
        if (this.garantia.hasOwnProperty(campo.clave)) {
          let valor = campo.valor_origen ? campo.valor_origen : null;

          if (campo.tipo_dato === 'date' && !!valor) {
            valor = this.$moment(valor).format("YYYY-MM-DD")
          }

          this.garantia[campo.clave] = valor
        }
      }
    }
  }
  ,  watch: {
    'garantia.estado': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {

          if (oldValue != null)
            this.garantia.municipio = null

          if (!this.solo_lectura)
            this.cargar_municipios()
        }
      }
    }
  }

}
</script>

<style scoped lang="scss">

</style>
